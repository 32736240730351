import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const PaymentResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [paymentData, setPaymentData] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const userId = sessionStorage.getItem('userId'); // 获取 user_id

  useEffect(() => {
    const fetchPaymentResult = async () => {
      const searchParams = new URLSearchParams(location.search);
      const paymentIntent = searchParams.get('payment_intent');

      if (!paymentIntent) {
        setError(t('missing_payment_intent'));
        setShowLoading(false);
        setLoading(false);
        return;
      }

      const requestData = { payment_result_id: paymentIntent };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/payment_result`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData),
        });

        if (!response.ok) {
          setError(t('payment_failed'));
          return;
        }

        const data = await response.json();

        if (data.status === 'succeeded') {
          setPaymentData(data);
          const balanceResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/balance`, { user_id: userId });
          // 更新余额并触发更新事件
          const newBalance = await balanceResponse.data.balance;
          sessionStorage.setItem('balance', newBalance.toString());
          // 触发余额更新事件
          window.dispatchEvent(new Event('balanceUpdated'));

          //同步更新推广码到后端,在这里进行复杂的逻辑，先判断referral是否存在，如果存在就把credits和promoCode拉出来，并且和user_id组合起来形成一个API请求的输入
          const referral = sessionStorage.getItem('referral');
          if (referral) {
            const credits = parseInt(sessionStorage.getItem('credits').replace(/,/g, ''), 10)|| 0;
            const promoCode = sessionStorage.getItem('promoCode') || '';

            // 组成请求数据
            const syncData = {
              user_id: userId,
              refer_code: promoCode,
              recharge_credit: credits,
            };
            console.log(syncData)
            try {
              const referResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/use_refer_code`, syncData);

              if (referResponse.status === 200) {
                console.log("Referral information synced successfully.");
              } else {
                console.error("Failed to sync referral information.");
              }
            } catch (err) {
              console.error("Error syncing referral information:", err);
            }
          }

          // referral 向后端同步代码的结束
        } else {
          setError(t('payment_failed'));
        }
      } catch (error) {
        setError(t('payment_failed'));
      } finally {
        setShowLoading(false);
        setLoading(false);
      }
    };

    fetchPaymentResult();
  }, [location, t]);

  return (
    <Container className="p-5 rounded shadow-lg" style={{ marginTop: '60px', backgroundColor: '#fff', fontFamily: 'Arial, sans-serif', textAlign: 'center' }}>
      <h1 className="mb-4" style={{ fontWeight: 'bold', fontSize: '2rem', color: '#333' }}>{t('payment_result')}</h1>
      {loading ? (
        <Modal show={showLoading} centered>
          <Modal.Body className="text-center">
            <Spinner animation="border" role="status" style={{ color: '#4A90E2' }}>
              <span className="visually-hidden">{t('loading')}</span>
            </Spinner>
            <p className="mt-3">{t('confirming_order')}</p>
          </Modal.Body>
        </Modal>
      ) : error ? (
        <Alert variant="light" className="p-4">
          <i className="fas fa-times-circle" style={{ fontSize: '3rem', color: '#E74C3C' }}></i>
          <p className="mt-3" style={{ fontSize: '1.2rem', color: '#333' }}>{error}</p>
          <Button 
            variant="dark" 
            className="m-2" 
            style={{ fontSize: '1rem', padding: '10px 20px' }} 
            onClick={() => navigate('/recharge')}
          >
            {t('try_again')}
          </Button>
        </Alert>
      ) : paymentData ? (
        <div className="text-center">
          <i className="fas fa-check-circle" style={{ fontSize: '3rem', color: '#2ECC71' }}></i>
          <p className="mt-3" style={{ fontSize: '1.5rem', color: '#2ECC71' }}>{t('payment_success')}</p>
          <p style={{ fontSize: '1.2rem', color: '#333' }}>{t('payment_amount')}: <span style={{ fontWeight: 'bold' }}>{paymentData.amount} {paymentData.currency.toUpperCase()}</span></p>
          <p style={{ fontSize: '1.2rem', color: '#333' }}>{t('payment_time')}: <span style={{ fontWeight: 'bold' }}>{paymentData.created}</span></p>
          <Button 
            variant="dark" 
            className="m-2" 
            style={{ fontSize: '1rem', padding: '10px 20px' }} 
            onClick={() => navigate('/recharge')}
          >
            {t('continue_recharge')}
          </Button>
          <Button 
            variant="dark" 
            className="m-2" 
            style={{ fontSize: '1rem', padding: '10px 20px' }} 
            onClick={() => navigate('/AI_detector')}
          >
            {t('start_using')}
          </Button>
        </div>
      ) : (
        <p className="text-center">{t('unknown_error')}</p>
      )}
    </Container>
  );
};

export default PaymentResult;
