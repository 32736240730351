// pages/ContactSupport.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation

const ContactSupport = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // 使用 useTranslation

  const copyToClipboard = () => {
    navigator.clipboard.writeText('nlphraser@gmail.com');
    alert(t('email_copied')); // 替换硬编码的提示文本
  };

  return (
    <div className="container mt-5">
      <style>
        {`
          .contact-section {
            padding: 20px;
            background-color: #f8f9fa;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          }

          .contact-section h3 {
            font-weight: 600;
            font-size: 1.5rem;
          }

          .contact-section .email {
            font-weight: bold;
            color: #333;
            margin-bottom: 10px;
            cursor: text;
          }

          .contact-section .copy-btn {
            margin-top: 10px;
            font-weight: bold;
            color: #007bff;
            transition: color 0.3s ease;
          }

          .contact-section .copy-btn:hover {
            color: #0056b3;
          }
        `}
      </style>

      <h2 className="text-center mb-4">{t('faq_title')}</h2>
      <div className="accordion" id="faqAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {t('faq_question1')}
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              {t('faq_answer1')}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              {t('faq_question2')}
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              {t('faq_answer2')}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              {t('faq_question3')}
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              {t('faq_answer3')}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
              {t('faq_question4')}
            </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              {t('faq_answer4')}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              {t('faq_question5')}
            </button>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              {t('faq_answer5')}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              {t('faq_question6')}
            </button>
          </h2>
          <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faqAccordion">
            <div className="accordion-body">
              {t('faq_answer6')}
            </div>
          </div>
        </div>
      </div>

      <div className="contact-section mt-5 text-center">
        <h3 className="mb-3">{t('contact_us')}</h3>
        <p>{t('contact_us_text')}</p>
        <p className="email">nlphraser@gmail.com</p>
        <button className="btn btn-outline-primary copy-btn" onClick={copyToClipboard}>
          {t('copy_email')}
        </button>
      </div>

      <div className="text-center mt-4">
        <button className="btn btn-primary btn-lg" onClick={() => navigate('/')}>{t('back_home')}</button>
      </div>
    </div>
  );
};

export default ContactSupport;
