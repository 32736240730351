import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PaymentModal from '../components/PaymentModal';
import './css/Recharge.css';
import { useTranslation } from 'react-i18next';

const basePrices = {
  entry: 24.9,
  basic: 99,
  standard: 199,
  premium: 399,
  diamond: 799,
  enterprise: 1599,
};

const exchangeRates = {
  CNY: 1, // 人民币汇率
  USD: 0.14, // 美金汇率
  CAD: 0.18, // 加币汇率
};

const currencySymbols = {
  CNY: '￥',
  USD: '$',
  CAD: 'C$',
};

const Recharge = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState('CNY'); // 默认货币为人民币
  const [showLoginModal, setShowLoginModal] = useState(false);
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!userId) {
      setShowLoginModal(true);
    }
  }, [userId]);

  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  const handleHomeRedirect = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  const getConvertedPrice = (priceInCNY) => {
    return Math.round(priceInCNY * exchangeRates[currency] * 100); // 保证价格以“分”为单位
  };

  const packages = [
    { id: 1, name: t('entry_package'), price: getConvertedPrice(basePrices.entry), credits: '500', stripePrice: basePrices.entry },
    { id: 2, name: t('basic_package'), price: getConvertedPrice(basePrices.basic), credits: '2,000', stripePrice: basePrices.basic },
    { id: 3, name: t('standard_package'), price: getConvertedPrice(basePrices.standard), credits: '4,200', stripePrice: basePrices.standard },
    { id: 4, name: t('premium_package'), price: getConvertedPrice(basePrices.premium), credits: '11,000', stripePrice: basePrices.premium },
    { id: 5, name: t('diamond_package'), price: getConvertedPrice(basePrices.diamond), credits: '23,000', stripePrice: basePrices.diamond },
    { id: 6, name: t('enterprise_package'), price: getConvertedPrice(basePrices.enterprise), credits: '120,000', stripePrice: basePrices.enterprise },
  ];

  const handlePackageSelect = (pkg) => {
    setSelectedPackage({ ...pkg, currency });
    setShowModal(true);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  return (
    <div className="container recharge-container bg-white p-4 rounded shadow" style={{ marginTop: '60px' }}>
      <h1 className="text-center">{t('recharge')}</h1>
      <p className="text-center">{t('recharge_description')}</p>
      
      {/* 货币选择器与支付方式图标部分 */}
      <div className="currency-selector text-center mb-3 d-flex align-items-center justify-content-center">
        <div className="payment-icons d-flex justify-content-center">
          <i className="bi bi-credit-card mx-2" title={t('credit_card')}></i>
          <i className="bi bi-paypal mx-2" title={t('paypal')}></i>
          <i className="bi bi-apple mx-2" title={t('apple_pay')}></i>
          <i className="bi bi-google mx-2" title={t('google_pay')}></i>
          <i className="bi bi-wallet2 mx-2" title={t('alipay')}></i>
          <i className="bi bi-wechat mx-2" title={t('wechat_pay')}></i>
        </div>
        <div className="text-center ms-3">
          <label htmlFor="currencySelect" className="form-label">{t('select_currency')}</label>
          <select
            id="currencySelect"
            value={currency}
            onChange={handleCurrencyChange}
            className="form-select form-select-lg"
            style={{ maxWidth: '300px' }}
          >
            <option value="CNY">{t('currency_cny')}</option>
            <option value="USD">{t('currency_usd')}</option>
            <option value="CAD">{t('currency_cad')}</option>
          </select>
        </div>
      </div>
      
      <Row className="justify-content-center">
        {packages.map((pkg) => (
          <Col key={pkg.id} xs={12} sm={6} md={4} className="mb-4">
            <Card className="package-card">
              <Card.Body>
                <Card.Title>{pkg.name}</Card.Title>
                <Card.Text className="price">{currencySymbols[currency]}{(pkg.price / 100).toFixed(2)}</Card.Text>
                <Card.Text className="credits">{pkg.credits} NLPhraser {t('credits')}</Card.Text>
                <Button variant="success" onClick={() => handlePackageSelect(pkg)}>{t('select_package')}</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {selectedPackage && (
        <PaymentModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedPackage={selectedPackage}
          currency={currency} // 将 currency 传递给 PaymentModal
        />
      )}
      <Modal show={showLoginModal} onHide={handleHomeRedirect}>
        <Modal.Header closeButton>
          <Modal.Title>{t('not_logged_in')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontFamily: 'Arial, sans-serif', fontSize: '1rem' }}>
          <p>{t('login_prompt_history')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHomeRedirect}>
            {t('back_home')}
          </Button>
          <Button variant="primary" onClick={handleLoginRedirect}>
            {t('login')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Recharge;
