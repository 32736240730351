import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col, Badge, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const EarnCredits = () => {
  const [referCode, setReferCode] = useState('61EE98'); // 模拟的推广码
  const [referHistory, setReferHistory] = useState([]);
  const [totalCredits, setTotalCredits] = useState(0); // 新增：用于存储总积分
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState(''); // 状态保存复制成功信息
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      setShowLoginModal(true); // 如果没有登录，显示登录弹窗
    } else {
      fetchReferCodeAndHistory(); // 如果已登录，获取推广码和记录
    }
  }, [userId]);

  const fetchReferCodeAndHistory = async () => {
    setLoading(true);
    try {

      const referralHistoryResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get_refer_history`, { user_id: userId });
      
      const referHistoryData = referralHistoryResponse.data

      setReferHistory(referHistoryData.sort((a, b) => new Date(b.refer_time) - new Date(a.refer_time)));

      calculateTotalCredits(referHistoryData); // 计算获得的总积分
    } catch (error) {
      console.error('Error fetching refer code and history:', error);
    }
    setLoading(false);
  };

  const calculateTotalCredits = (history) => {
    const total = history.reduce((acc, item) => acc + parseFloat(item.recharge_credit || 0), 0) / 2;
    setTotalCredits(total);
  };

  // 复制推广码到剪贴板
  const copyToClipboard = () => {
    navigator.clipboard.writeText(referCode).then(() => {
      setCopySuccess('Refer code copied!');
      setTimeout(() => setCopySuccess(''), 2000); // 2 秒后清除提示
    }).catch(err => {
      console.error('Failed to copy refer code: ', err);
    });
  };

  // 登录重定向逻辑
  const handleLoginRedirect = () => {
    setShowLoginModal(false);
    navigate('/login');
  };

  const handleHomeRedirect = () => {
    setShowLoginModal(false);
    navigate('/');
  };

  // 渲染推广历史记录
  const renderReferHistory = () => {
    if (referHistory.length === 0) {
      return (
        <div className="bg-white p-4 rounded" style={{ textAlign: 'center', border: '1px solid #ddd' }}>
          <p>暂无推广记录</p>
        </div>
      );
    }

    return (
      <div className="bg-white p-4 rounded" style={{ border: '1px solid #ddd' }}>
        {/* refer History 标题部分，底色加深 */}
        <div style={{ backgroundColor: '#e0e0e0', padding: '10px', borderRadius: '5px 5px 0 0', textAlign: 'center' }}>
          <h4 className="mb-0">Refer History</h4>
        </div>

        {/* 分割线，标题和记录之间 */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            width: '80%',
            height: '1px',
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%)',
            margin: '10px 0'
          }}></div>
        </div>

        <Container fluid>
  {referHistory.map((item, index) => (
    <div key={`refer-${index}`}>
      <Row className="mb-3">
        <Col><Badge bg="info" className="px-2 py-1">From User:</Badge> {item.refer_from_user_name}</Col>
        <Col><Badge bg="success" className="px-2 py-1">To User:</Badge> {item.refer_to_user_name}</Col>
        <Col><Badge bg="warning" className="px-2 py-1">Refer Time:</Badge> {new Date(item.refer_time).toLocaleString('zh-CN', { hour12: false })}</Col>
        <Col><Badge bg="danger" className="px-2 py-1">Recharge Credit:</Badge> {item.recharge_credit}</Col>
      </Row>

      {/* Divider */}
      {index < referHistory.length - 1 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            width: '80%',
            height: '1px',
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%)',
            margin: '10px 0'
          }}></div>
        </div>
      )}
    </div>
  ))}
</Container>

      </div>
    );
  };

  const styles = {
    container: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      marginBottom: '20px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '1rem',
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontWeight: '530',
    },
    totalCredits: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '2rem' }}>Earn Credits</h1>
     

      <div style={{ ...styles.container, flexDirection: 'column', alignItems: 'center' }}>
  <span style={styles.totalCredits}>Total Credits Earned: {totalCredits}</span>

  {/* 小字放到总积分下面，垂直排列 */}
  <p style={styles.rewardText}>When the invitee recharges for the first time, You will receive 50% Spear Credit as a reward.</p>
  
  <button
    className={`btn btn-outline-success btn-lg`}
    style={styles.button}
    onClick={copyToClipboard}
  >
    Copy Refer Code
  </button>
</div>



      {copySuccess && <p style={{ color: 'green', textAlign: 'center' }}>{copySuccess}</p>}

      {/* 显示 refer History */}
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>{renderReferHistory()}</div>
      )}

      {/* 登录弹窗 */}
      <Modal show={showLoginModal} onHide={handleHomeRedirect} centered>
        <Modal.Header closeButton>
          <Modal.Title>Not Logged In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You need to log in to view your refer code and history.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHomeRedirect}>
            Back to Home
          </Button>
          <Button variant="primary" onClick={handleLoginRedirect}>
            Log In
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EarnCredits;
